import React, { useState, useEffect } from 'react';
import './styles.css';
import { OrgChartComponent } from '../../components/Chart/org';
import csv from '../../assets/csv/org5.csv'
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import LoginService from "../../services/auth.service";
import { Navigate } from 'react-router-dom';
import { logout } from "../../redux/actions/auth";

const Main = () => {
    const [data, setData] = useState(null);
    const [profile, setProfile] = useState({});
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
      LoginService.profile().then(
        (response) => {
            let {data} = response.data;
            setProfile(data);
        },
        (error) => {
            const _content = 
            (error.response && 
                error.response.data && 
                error.response.data.message) ||
            error.message ||
            error.toString();

            console.log(_content);

            if(error.response && error.response.status === 401) {
              dispatch(logout());
          }
        }
      )

      d3.csv(
        csv
      ).then((data) => {
        setData(data);
      });

    }, []);

    if(!currentUser) {
      return <Navigate to="/login" />;
    }

    return (
        <OrgChartComponent 
            data={data}
        />
    )
}

export default Main;