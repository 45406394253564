import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as pages from '../pages';

const AppRouter = () => (
    <Routes>
        <Route path='/' element={<pages.Main />} />
        <Route path='/login' element={<pages.Login />} />
    </Routes>
)

export default AppRouter;