import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://org-api.soechigroup.com/api/auth";
// const API_URL = "http://127.0.0.1:8000/api/auth";

const login = (username, password) => {
    return axios
        .post(API_URL + "/login", {
            username,
            password
        })
        .then((response) => {
            const {data} = response.data;
            if(data.access_token) {
                localStorage.setItem("user", JSON.stringify(data));
            }

            return response.data;
        });
}

const profile = () => {
    return axios.get(API_URL + "/profile", { headers: authHeader() });
}

const logout = () => {
    localStorage.removeItem("user");
}

export default {
    login,
    profile,
    logout
}